import { ApolloClient, ApolloProvider } from '@apollo/client';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Div100Vh from 'react-div-100vh';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AppLogo, ThemeProvider } from 'amnesty-components';
import { HelmetProvider } from 'react-helmet-async';
import ErrorPage from './ErrorPage';
import HomePage from './HomePage';
import generateUrl from './services/generateUrl';
import AppBackground from './themes/AppBackground';
import DesktopAlert from './themes/DesktopAlert';
import RouterScrollToTop from './themes/RouterScrollToTop';
import UrgentAction from './urgentActions/UrgentAction';

export const Div100Vw = styled.div`
    & {
        width: 100vw;
    }
`;

const App = ({ className, client }: { className?: string; client: ApolloClient<unknown> }) => {
    useEffect(() => {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        document.getElementById('root').className = 'loaded';
    });

    return (
        <HelmetProvider>
            <ApolloProvider client={client}>
                <ThemeProvider>
                    <Div100Vw>
                        <Div100Vh className={className}>
                            <AppBackground />
                            <AppLogo />
                            <DesktopAlert />
                            <BrowserRouter>
                                <RouterScrollToTop>
                                    <Routes>
                                        <Route path={generateUrl('home')} element={<HomePage />} />
                                        <Route path={generateUrl('error')} element={<ErrorPage />} />
                                        <Route path="/ua/:slug" element={<UrgentAction />} >
                                            <Route path=":step" element={<UrgentAction />}>
                                                <Route path=":page/*" element={<UrgentAction />} />
                                            </Route>
                                        </Route>
                                        <Route
                                            path="*"
                                            element={<Navigate to={generateUrl('error')} />}
                                        />
                                    </Routes>
                                </RouterScrollToTop>
                            </BrowserRouter>
                        </Div100Vh>
                    </Div100Vw>
                </ThemeProvider>
            </ApolloProvider>
        </HelmetProvider>
    );
};

App.propTypes = {
    className: PropTypes.string,
    client: PropTypes.object.isRequired,
};

export default App;
